import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"];

  connect() {
  }

  addTextField(event) {
    const fieldId = this.containerTarget.id.split('-').pop();
    const newField = document.createElement("input");
    newField.type = "text";
    newField.name = `responses[${fieldId}][]`;
    newField.className = "w-full input input-bordered input-lg mt-2";
    this.containerTarget.appendChild(newField);
  }

  addTextArea(event) {
    const fieldId = this.containerTarget.id.split('-').pop();
    const newField = document.createElement("textarea");
    newField.name = `responses[${fieldId}][]`;
    newField.className = "w-full textarea textarea-bordered textarea-lg mt-2";
    this.containerTarget.appendChild(newField);
  }
}