import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.hideMessageAfterDelay();
  }

  hideMessageAfterDelay() {
    setTimeout(() => {
      this.element.style.display = "none";
    }, 3000);
  }
}
