import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  reset() {
    this.element.reset()
    if (this.inputTarget) {
      this.inputTarget.focus()
    }
  }

  submitOnEnter(event) {
    if (event.key === "Enter") {
      event.preventDefault()
      this.element.requestSubmit()
      setTimeout(() => {
        this.reset()
      }, 100)
    }
  }
}