import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "form"];

  connect() {
  }

  filterEmptyFields(event) {
    event.preventDefault(); // Prevent the default form submission

    const inputs = this.containerTarget.querySelectorAll('input[type="text"]');
    const nonEmptyValues = Array.from(inputs)
      .map(input => input.value)
      .filter(value => value.trim() !== ""); // Filter out empty values

    // Remove empty input fields from the DOM
    inputs.forEach(input => {
      if (input.value.trim() === "") {
        input.remove(); // Remove the input field if it's empty
      }
    });

    // Now submit the form using the form target
    this.formTarget.submit(); // Submit the form after filtering
  }
}