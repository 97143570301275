import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["repeatType", "conditionalFields"];

  connect() {
    this.updateConditionalFields();
    this.repeatTypeTarget.addEventListener('change', () => this.updateConditionalFields());
  }

  updateConditionalFields() {
    const selectedValue = this.repeatTypeTarget.value;
    this.conditionalFieldsTargets.forEach(field => {
      field.style.display = 'none'; // Hide all fields initially
    });

    if (selectedValue) {
      const targetField = document.getElementById(`${selectedValue}-fields`);
      if (targetField) {
        targetField.style.display = 'block'; // Show the selected field
      }
    }
  }
}